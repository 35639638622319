export const WrapNameGift = ({ imageSrc }: { imageSrc: string }) => {
  return (
    <svg
      viewBox="0 0 200 147"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g clipPath="url(#clip0_4121_5825)">
        <rect
          x="118.083"
          width="76.5234"
          height="76.5234"
          rx="12"
          transform="rotate(21.6289 118.083 0)"
          fill="url(#pattern0)"
        />
        <path
          d="M66.4411 131.223V67.8878C66.4411 63.4695 70.0228 59.8878 74.4411 59.8878H175.773C180.192 59.8878 183.773 63.4695 183.773 67.8878V131.223C183.773 140.16 176.523 147.43 167.611 147.43H82.6084C73.6936 147.43 66.4415 140.16 66.4411 131.223Z"
          fill="#EC5F5F"
        />
        <rect x="114.253" y="59.8878" width="20.8754" height="87.5421" fill="#FEBE44" />
        <path
          d="M70.4411 131.223C70.4411 131.223 70.4411 131.223 70.4411 131.223V67.8878C70.4411 65.6787 72.2319 63.8878 74.4411 63.8878H175.773C177.982 63.8878 179.773 65.6787 179.773 67.8878V131.223C179.773 137.961 174.303 143.43 167.611 143.43H82.6084C75.9122 143.43 70.4415 137.96 70.4411 131.223Z"
          stroke="black"
          strokeOpacity="0.15"
          strokeWidth="8"
          strokeLinejoin="round"
        />
        <path
          d="M51.7507 37.8385L54.1514 32.7995C57.9846 24.7534 67.6569 21.3292 75.7124 25.1664L92.3481 33.0918C94.2848 34.0144 95.2106 36.1962 94.6219 38.1902L90.9721 45.989L47.9148 136.368L44.1581 144.116C42.9805 145.829 40.7029 146.485 38.7662 145.562L22.1305 137.637C14.076 133.8 10.6421 124.131 14.4753 116.085L16.876 111.046L51.7507 37.8385Z"
          fill="#EC5F5F"
        />
        <path
          d="M43.5877 34.1677L51.6679 38.0174L16.7912 111.224L8.7095 107.373C2.95214 104.63 0.49769 97.7195 3.23785 91.9682C5.30226 87.6352 10.8404 81.354 16.4059 77.0325C20.115 74.153 23.6724 72.1972 27.0016 71.1769C25.6965 67.9487 24.9749 63.9547 24.8741 59.2605C24.7228 52.2162 26.1118 43.9582 28.1767 39.624C30.9171 33.8724 37.8304 31.4246 43.5877 34.1677Z"
          fill="#FEBE44"
        />
        <path
          d="M35.95 43.3276C34.8674 45.6 33.3347 52.1767 33.4823 59.0756C33.5925 64.188 34.6199 68.1464 36.3087 70.257L47.9645 45.7907L39.8843 41.941C38.413 41.2399 36.6482 41.8623 35.95 43.3276Z"
          fill="#C99635"
        />
        <path
          d="M11.0111 95.6717C10.313 97.137 10.9417 98.8992 12.4131 99.6002L20.4949 103.45L32.1654 78.9533C25.25 78.9219 13.5475 90.3482 11.0111 95.6717Z"
          fill="#C99635"
        />
        <path
          d="M29.5363 84.4756L38.5147 65.6296L73.6196 82.3538L64.6412 101.2L29.5363 84.4756Z"
          fill="#FEBE44"
        />
        <path
          d="M20.4871 112.766L20.4871 112.766L55.3619 39.5589L57.7625 34.5199C60.6438 28.4719 67.9272 25.8886 73.992 28.7775L90.6277 36.7029C90.6971 36.736 90.7415 36.7835 90.7701 36.8487C90.7823 36.8765 90.7903 36.9046 90.7943 36.9323L87.361 44.2686L87.3551 44.2809L44.3095 134.635L40.7696 141.936C40.7456 141.95 40.7188 141.962 40.6895 141.97C40.6208 141.989 40.5559 141.984 40.4866 141.951L23.8508 134.026C17.7874 131.137 15.2049 123.854 18.0865 117.805L20.4871 112.766Z"
          stroke="black"
          strokeOpacity="0.15"
          strokeWidth="8"
        />
        <g clipPath="url(#clip1_4121_5825)">
          <path
            d="M178.324 15.5716C180.143 16.0328 181.572 17.4609 182.033 19.2808C182.077 19.4577 182.305 19.4577 182.349 19.2808C182.81 17.4609 184.238 16.0328 186.058 15.5716C186.235 15.5273 186.235 15.2998 186.058 15.2556C184.238 14.7943 182.81 13.3662 182.349 11.5464C182.305 11.3695 182.077 11.3695 182.033 11.5464C181.572 13.3662 180.143 14.7943 178.324 15.2556C178.147 15.2998 178.147 15.5273 178.324 15.5716Z"
            fill="#FFB636"
          />
          <path
            d="M165.458 23.3312C168.668 24.1464 171.196 26.6739 172.011 29.884C172.087 30.1936 172.485 30.1936 172.567 29.884C173.382 26.6739 175.91 24.1464 179.12 23.3312C179.429 23.2554 179.429 22.8573 179.12 22.7752C175.903 21.9663 173.376 19.4388 172.567 16.2287C172.491 15.9191 172.093 15.9191 172.011 16.2287C171.196 19.4388 168.668 21.9663 165.458 22.7815C165.149 22.8573 165.149 23.2554 165.458 23.3312V23.3312Z"
            fill="#FFD469"
          />
          <path
            d="M176.409 33.5932C181.211 34.8127 184.996 38.5915 186.21 43.3938C186.33 43.8551 186.924 43.8551 187.037 43.3938C188.257 38.5915 192.036 34.8064 196.838 33.5932C197.299 33.4731 197.299 32.8791 196.838 32.7654C192.036 31.5458 188.251 27.7671 187.037 22.9647C186.917 22.5035 186.323 22.5035 186.21 22.9647C184.99 27.7671 181.211 31.5522 176.409 32.7654C175.948 32.8791 175.948 33.4731 176.409 33.5932V33.5932Z"
            fill="#FFE1AB"
          />
        </g>
      </g>
      <defs>
        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_4121_5825" transform="scale(0.0037037)" />
        </pattern>
        <clipPath id="clip0_4121_5825">
          <rect width="200" height="147" fill="white" />
        </clipPath>
        <clipPath id="clip1_4121_5825">
          <rect
            width="32.3529"
            height="32.3529"
            fill="white"
            transform="translate(165.035 11.4327)"
          />
        </clipPath>
        <image id="image0_4121_5825" width="270" height="270" xlinkHref={imageSrc} />
      </defs>
    </svg>
  )
}
